
<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dae_subsidy.bill_request') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('org_pro_division.division')"
                  label-for="division_id"
                  >
                  <b-form-select
                      plain
                      v-model="search.division_id"
                      :options="divisionList"
                      id="division_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="3"
                  :label="$t('org_pro_district.district')"
                  label-for="district_id"
                  >
                  <b-form-select
                      plain
                      v-model="search.district_id"
                      :options="districtList"
                      id="district_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('org_pro_upazilla.upazilla')"
                  label-for="upazilla_id"
                  >
                  <b-form-select
                      plain
                      v-model="search.upazilla_id"
                      :options="upazilaList"
                      id="upazilla_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('dae_config.instrument_name')"
                  label-for="instrument_id"
                >
                    <b-form-select
                      plain
                      v-model="search.instrument_id"
                      :options="instrumentList"
                      id="instrument_id"
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row>
          <b-col md="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('dae_subsidy.bill_request') }} {{ $t('globalTrans.list') }} </h4>
              </template>
              <template v-slot:body>
                  <b-overlay :show="loadingState">
                      <b-row>
                          <b-col md="12" class="table-responsive">
                              <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                </template>
                                <template v-slot:cell(mobile_no)="data">
                                  {{ $n(data.item.mobile_no, { useGrouping: false }) }}
                                </template>
                                <template v-slot:cell(nid)="data">
                                  {{ $n(data.item.nid, { useGrouping: false }) }}
                                </template>
                                <template v-slot:cell(status)="data">
                                  {{ getStatus(data.item.status) }}
                                </template>
                                <template v-slot:cell(action)="data">
                                  <b-button class="mr-1" title="Comment" v-b-modal.modal-3 variant=" iq-bg-success" size="sm" @click="forwardComment(data.item)"><i class="ri-list-check"></i></b-button>
                                  <!-- <b-button class="mr-1" title="Reject" v-b-modal.modal-2 variant=" iq-bg-danger" size="sm" @click="rejectApprove(data.item, 4)"><i class="ri-close-line"></i></b-button> -->
                                  <b-button class="mr-1" title="Approve" v-b-modal.modal-2 variant=" iq-bg-success" size="sm" @click="rejectApprove(data.item, 3)"><i class="ri-check-line"></i></b-button>
                                  <b-button class="mr-1" title="Forward" v-b-modal.modal-1 variant=" iq-bg-success" size="sm" @click="forward(data.item)"><i class="ri-arrow-right-line"></i></b-button>
                                  <b-button class="mr-1" title="View" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                                  <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                </template>
                              </b-table>
                              <b-pagination
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total-rows="pagination.totalRows"
                                @input="searchData"
                              />
                          </b-col>
                      </b-row>
                  </b-overlay>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :id="itemId" :key="itemId"></Form>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('dae_subsidy.bill_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="item"></Details>
        </b-modal>
        <b-modal id="modal-1" size="lg" :title="$t('budget.forward')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Forward :id="itemId" :key="itemId"/>
        </b-modal>
        <b-modal id="modal-2" size="lg" :title="rejectApproveTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <RejectApprove :id="itemId" :status="status" :key="itemId"/>
        </b-modal>
        <b-modal id="modal-3" size="lg" :title="$t('budget.forwardComment')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <ForwardComment :item="item" :key="itemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Details from './Details'
import Forward from './Forward'
import RejectApprove from './RejectApprove'
import ForwardComment from './ForwardComment'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
// import { } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details, Forward, RejectApprove, ForwardComment, Form
  },
  data () {
    return {
      search: {
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        instrument_id: 0,
        receiver_id: this.$store.state.Auth.authUser.user_id
      },
      subsidyList: [],
      districtList: [],
      upazilaList: [],
      item: '',
      itemId: 0,
      status: 0,
      rejectApproveTitle: ''
    }
  },
  computed: {
    instrumentList: function () {
      const instrumentList = this.$store.state.incentiveGrant.commonObj.instrumentList.filter(item => item.status === 1)
      return instrumentList.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    formTitle () {
      return (this.itemId === 0) ? this.$t('budget.budget') + ' ' + this.$t('globalTrans.entry') : this.$t('budget.budget') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('common_config.farmer_name'), class: 'text-left' },
        { label: this.$t('common_config.mobile_no'), class: 'text-left' },
        { label: this.$t('common_config.nid_no'), class: 'text-left' },
        { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-left' },
        { label: this.$t('dae_config.instrument_name'), class: 'text-left' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'farmer_name_bn' },
          { key: 'mobile_no' },
          { key: 'nid' },
          { key: 'upazilla_name_bn' },
          { key: 'instrument_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'farmer_name' },
          { key: 'mobile_no' },
          { key: 'nid' },
          { key: 'upazilla_name' },
          { key: 'instrument_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    forwardComment (item) {
      this.item = item
    },
    forward (item) {
      this.itemId = item.id
    },
    rejectApprove (item, status) {
      if (status === 3) {
        this.rejectApproveTitle = this.$t('budget.approve')
      } else {
        this.rejectApproveTitle = this.$t('budget.reject')
      }
      this.itemId = item.id
      this.status = status
    },
    details (item) {
      this.item = item
    },
    edit (item) {
      this.itemId = item.id
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(incentiveGrantServiceBaseUrl, 'dae/subsidy/bill-prepare/request-list', params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const listData = data.map(item => {
        const farmerData = {
          farmer_name: item.application.farmer !== undefined ? item.application.farmer.name : '',
          farmer_name_bn: item.application.farmer !== undefined ? item.application.farmer.name_bn : '',
          mobile_no: item.application.farmer !== undefined ? item.application.farmer.mobile_no : '',
          nid: item.application.farmer !== undefined ? item.application.farmer.nid_no : ''
        }
        const instrument = this.$store.state.incentiveGrant.commonObj.instrumentList.find(fyr => fyr.value === item.instrument_id)
        const upazillaList = this.$store.state.commonObj.upazilaList.find(upizila => upizila.value === item.application.farmer.address_info.upazilla_id)
        const instrumentObj = { instrument_name: instrument !== undefined ? instrument.text_en : '', instrument_name_bn: instrument !== undefined ? instrument.text_bn : '' }
        const upazillaObj = { upazilla_name: upazillaList !== undefined ? upazillaList.text_en : '', upazilla_name_bn: upazillaList !== undefined ? upazillaList.text_bn : '' }
        return Object.assign({}, item, instrumentObj, upazillaObj, farmerData)
      })
      return listData
    },
    getSubsidyList (subsidyTypeId) {
      return this.$store.state.incentiveGrant.commonObj.subsidyList.filter(item => item.status === 1 && item.subsidy_type_id === subsidyTypeId)
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('budget.pending')
      } else if (status === 2) {
        return this.$t('budget.forward')
      } else if (status === 3) {
        return this.$t('budget.approve')
      } else if (status === 4) {
        return this.$t('budget.reject')
      } else if (status === 5) {
        return this.$t('budget.fundDisbursed')
      }
    },
    getDistrictList (divisionId = null) {
        const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

        if (divisionId) {
            return districtList.filter(district => district.division_id === divisionId)
        }

        return districtList
    },
    getUpazilaList (districtId = null) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

        if (districtId) {
            return upazilaList.filter(upazila => upazila.district_id === districtId)
        }

        return upazilaList
    }
  }
}
</script>
